<template>
  <div class="cmp cmp-header">
    <div class="cmp-header-left">
      <img src="../../assets/images/index/logo.png">
    </div>
    <div class="cmp-header-middle">
      <a href="javascript:;" v-for="(v,i) in menusList" :key="i"
        :class="['menu-item', {active: v.name === $route.name}]"
        @click="$router.push({name: v.name})"
      >{{v.label}}</a>
    </div>
    <div class="cmp-header-right">
      <template v-if="userInfo.token">
        <a v-if="companyInfo.auditStatus==='NOT_AUDIT'" href="javascript:;" @click="gotoPage('NOT_AUDIT')">
          <i class="fa fa-id-card-o"></i>企业认证<span class="text-small text-warning">（审核中）</span>
        </a>
        <a v-else-if="companyInfo.auditStatus==='NOT_PASS'" href="javascript:;" @click="gotoPage('NOT_PASS')">
          <i class="fa fa-id-card-o"></i>企业认证<span class="text-small text-danger">（审核未通过）</span>
        </a>
        <a v-else-if="companyInfo.auditStatus==='UNVERIFIED'" href="javascript:;" @click="gotoPage('UNVERIFIED')">
          <i class="fa fa-id-card-o"></i>企业认证<!-- <span class="text-small text-warning">（未认证）</span> -->
        </a>
        <a v-else-if="companyInfo.auditStatus==='PASS'" href="javascript:;" @click="gotoPage('PASS')">我的应用</a>
        <a href="javascript:;">
          <el-dropdown trigger="click" @command="commandHandler">
            <span class="el-dropdown-link">
              <i class="fa fa-user-circle-o"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="modifyauthinfo">修改认证信息</el-dropdown-item>
                <el-dropdown-item command="changepassword">修改登录密码</el-dropdown-item>
                <el-dropdown-item command="myapp" :disabled="companyInfo.auditStatus!=='PASS'">我的应用</el-dropdown-item>
                <el-dropdown-item command="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </a>
      </template>
      <template v-else>
        <a href="javascript:;" @click="$router.push({name: 'login'})">登录</a>
        <a href="javascript:;" @click="$router.push({name: 'register'})">注册</a>
      </template>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex'
import publicUrl from '../../assets/js/interface'
export default {
  name: 'cmp-header',
  data () {
    return {
      loadingCount: 0,
      menusList: [
        {name: 'home', label: '首页'},
        {name: 'document', label: '文档中心'}
      ]
    }
  },
  computed: {
    ...mapState(['userInfo', 'companyInfo'])
  },
  mounted () {
    window.vm = this
    this.$nextTick(() => {
      if (localStorage.token) this.getUserInfo()
    })
  },
  methods: {
    ...mapMutations(['setUserInfo', 'resetData']),
    ...mapActions(['companyManagerStatus']),
    getUserInfo () {
      this.loadingCount++
      this.$ajaxJson.post(publicUrl.refreshUserToken, {
        refreshToken: localStorage.token
      }).then((res) => {
        if (res.data.code === 0) {
          let _data = res.data.data || {}
          localStorage.setItem('token', _data.token)
          this.setUserInfo(_data)
          this.loadingCount++
          this.companyManagerStatus({
            vm: this,
            token: _data.token,
            ElMessage: this.$message
          }).then(() => {
            if (['login', 'register'].includes(this.$route.name)) {
              this.$router.push({name: 'home'})
            }
          }).finally(() => {
            this.loadingCount--
          })
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(err => {
        this.$ajaxError(err)
      }).finally(() => {
        this.loadingCount--
      })
    },
    commandHandler (command) {
      console.log(command)
      if (command === 'logout') {
        localStorage.setItem('token', null)
        this.resetData()
        this.$router.push({name: 'login'})
      } else if (command === 'modifyauthinfo' && this.companyInfo.auditStatus !== 'PASS') {
        this.$router.push({name: 'home'})
      } else {
        this.$router.push({name: command})
      }
    },
    gotoPage (type) {
      if (type === 'NOT_PASS' || type === 'UNVERIFIED') {
        this.$router.push({name: 'register', query: {step: 1}})
      } else if (type === 'NOT_AUDIT') {
        this.$router.push({name: 'register', query: {step: 2}})
      } else if (type === 'PASS') {
        this.$router.push({name: 'myapp'})
      }
    }
  }
}
</script>

<style lang="less" scoped>
.cmp-header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 76px;
  padding: 18px 80px;
  border-bottom: 1px solid rgba(255, 255, 255, .2);
  background-color: #358bff;
  display: flex;
  .cmp-header-left {
    width: 250px;
    img {
      height: 40px;
    }
  }
  .cmp-header-middle {
    width: 0;
    flex-grow: 1;
    text-align: center;
    .menu-item {
      display: inline-block;
      height: 40px;
      line-height: 40px;
      margin: 0 15px;
      color: #fff;
      text-decoration: none;
      &.active {
        border-bottom: 2px solid #fff;
      }
    }
  }
  .cmp-header-right {
    width: 250px;
    text-align: right;
    a {
      display: inline-block;
      height: 40px;
      line-height: 40px;
      margin: 0 15px;
      color: #fff;
      text-decoration: none;
      position: relative;
      &:not(:last-child)::after {
        content: "";
        position: absolute;
        right: -15px;
        top: 12px;
        bottom: 12px;
        width: 1px;
        background-color: #fff;
      }
    }
    .el-dropdown {
      .fa {color: #fff;}
    }
    .text-small {
      font-size: .9em;
    }
    .text-danger {
      color: #ed4014;
    }
    .text-warning {
      color: #ff9900;
    }
  }
}
</style>
