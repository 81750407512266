// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import ElementUI from 'element-ui'
import './assets/styles/reset.less'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/styles/font-awesome.css'
import './assets/styles/index.less'
import Index from './pages/index/index.vue'
import router from './router'
import store from './store'
import ajax from './assets/js/ajax'

Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(ajax)

/* eslint-disable no-new */

new Vue({
  router,
  store,
  render: h => h(Index)
}).$mount('#app')
