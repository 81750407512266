<template>
  <div :class="['cmp', 'cmp-body', planName]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'cmp-body',
  data () {
    return {}
  },
  computed: {
    planName () {
      let result = ''
      switch (this.$route.name) {
        case 'login':
        case 'register':
        case 'modifyauthinfo':
        case 'changepassword':
          result = 'plan-1'
          break
        case 'myapp':
        case 'createapp':
        case 'document':
          result = 'plan-2'
          break
        case 'home':
          result = ''
          break
      }
      return result
    }
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.cmp-body {
  position: fixed;
  left: 0;
  top: 76px;
  bottom: 120px;
  z-index: 2;
  width: 100%;
  overflow: hidden auto;
  &.plan-1 {
    background:
      url("../../assets/images/index/body_bg_1_top.png") no-repeat left top,
      url("../../assets/images/index/body_bg_1_bottom.png") no-repeat left bottom;
    background-size: 100% auto;
  }
  &.plan-2 {
    background:
      url("../../assets/images/index/body_bg_1_bottom.png") no-repeat left bottom;
    background-size: 100% auto;
  }
}
</style>
