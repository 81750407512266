<template>
  <div class="page-wrapper">
    <myheader></myheader>
    <mybody>
      <router-view></router-view>
    </mybody>
    <myfooter></myfooter>
  </div>
</template>

<script>
import myheader from './header.vue'
import mybody from './body.vue'
import myfooter from './footer.vue'
export default {
  name: 'page-wrapper',
  components: {
    myheader,
    mybody,
    myfooter
  },
  props: {
    msg: String
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
</style>
