import Vue from 'vue'
import Vuex from 'vuex'
import url from '../assets/js/interface'

Vue.use(Vuex)
// 这里为全局的，模块内的请在模块内动态注册
export default new Vuex.Store({
  state: {
    userInfo: {},
    companyInfo: {},
    managerInfo: {},
    // 课件制作 - 当前资源信息字典
    resourceInfoDict: {},
    registerData: {}
  },
  mutations: {
    setUserInfo (state, params) {
      state.userInfo = params || {}
    },
    setCompanyInfo (state, params) {
      state.companyInfo = {...state.companyInfo, ...(params || {})}
    },
    setManagerInfo (state, params) {
      state.managerInfo = params || {}
    },
    // 课件制作 - 当前资源信息字典
    setResourceInfoDict (state, params) {
      state.resourceInfoDict = {
        ...state.resourceInfoDict,
        ...(params || {})
      }
    },
    setRegisterData (state, params) {
      state.registerData = params || {}
    },
    resetData (state) {
      state.managerInfo = {}
      state.companyInfo = {}
      state.userInfo = {}
    }
  },
  actions: {
    resourceInfoGetter ({ commit }, {vm, fileNames, md5List}) {
      fileNames = fileNames || []
      md5List = md5List || fileNames
      if (fileNames.length === 0) return Promise.resolve()
      return vm.$ajaxJson.post(url.getBatchFileUrl, {
        expiration: 36000,
        fileNames: fileNames
      }).then(res => {
        let data = vm.$ajaxCheck(res)
        if (!data || !(data instanceof Array)) return
        let result = {}
        data.forEach((v, i) => {
          let s = /\?/.test(v) ? '&' : '?'
          result[md5List[i]] = `${v}${s}r=${Math.random()}`
        })
        commit('setResourceInfoDict', result)
      }).catch(err => {
        vm.$ajaxError(err)
      })
    },
    companyManagerStatus ({ commit }, {vm, token, ElMessage}) {
      let options = {}
      if (token) {
        options = {headers: {token}}
      }
      return vm.$ajax.get(url.companyManagerStatus, options).then(res => {
        if (res.data.code === 0) {
          let _data = res.data.data || ''
          commit('setCompanyInfo', {auditStatus: _data})
        } else {
          ElMessage && ElMessage.error(res.data.msg)
        }
      }).catch(err => {
        vm.$ajaxError(err)
      })
    }
  }
})
