<template>
  <div class="cmp cmp-footer">
    <el-row>
      <a href="javascript:;">版权所有 北京京师讯飞教育科技有限公司</a>
      <a href="javascript:;">网站备案编号：京ICP备16045443号-4</a>
    </el-row>
    <el-row>
      <a href="javascript:;">京公网安备11010202008407号</a>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'cmp-footer',
  data () {
    return {}
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.cmp-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 120px;
  padding: 20px 40px 0;
  background-color: #363636;
  .el-row {
    justify-content: center;
    a {
      display: inline-block;
      height: 40px;
      line-height: 40px;
      margin: 0 15px;
      color: #fff;
      text-decoration: none;
      position: relative;
      &:not(:last-child)::after {
        content: "";
        position: absolute;
        right: -15px;
        top: 12px;
        bottom: 12px;
        width: 1px;
        background-color: #fff;
      }
    }
  }
}
</style>
