// let baseUrl = 'http://jsxf-gateway-qa.qalb.jsxfedu.net'
let baseUrl = process.env.VUE_APP_API_URL || ''
let publicUrl = {
  // 公共
  getUploadSign: baseUrl + '/jsxf-open-platform/open/rs/uploadSign', // 获取上传签名信息
  getCode: baseUrl + '/jsxf-open-platform/code/', // 获取验证码
  getRegisterCode: baseUrl + '/jsxf-open-platform/company/send-register-code', // 获取手机验证码
  getChangeManagerCode: baseUrl + '/jsxf-open-platform/company/send-change-manager-code', // 获取手机验证码
  getBatchFileUrl: baseUrl + '/jsxf-open-platform/open/rs/post-batch-file-url', // 批量获取文件地址

  // 登录
  login: baseUrl + '/jsxf-open-platform/login/', // 登录
  getLoginCode: baseUrl + '/jsxf-open-platform/login/send-login-code', // 发送登录短信验证码

  // 修改登录密码
  changePassword: baseUrl + '/jsxf-open-platform/account/update-byorig-password', // 根据原密码修改登录密码

  // 企业认证
  company: baseUrl + '/jsxf-open-platform/company/', // 修改/添加企业认证
  companyCertification: baseUrl + '/jsxf-open-platform/company/certification', // 修改企业管理员信息
  companyInfo: baseUrl + '/jsxf-open-platform/company/info/', // 查询企业信息
  companyManagerStatus: baseUrl + '/jsxf-open-platform/company/company-status', // 查询登录用户企业状态
  companyRegister: baseUrl + '/jsxf-open-platform/company/register', // 企业注册
  companyManager: baseUrl + '/jsxf-open-platform/company/manager', // 修改企业管理员信息
  companyManagerInfo: baseUrl + '/jsxf-open-platform/company/manager-info', // 获取企业管理员信息
  refreshUserToken: baseUrl + '/jsxf-open-platform/company/refresh-user-token', // 刷新token

  // 我的应用
  appList: baseUrl + '/jsxf-open-platform/app/list', // 我的应用列表
  appAdd: baseUrl + '/jsxf-open-platform/app' // 添加企业应用
}

export default publicUrl
