import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {path: '/', redirect: '/home'},
    {path: '/home', name: 'home', component: () => import('../pages/home/index.vue')},
    {path: '/login', name: 'login', component: () => import('../pages/login/index.vue')},
    {path: '/register', name: 'register', component: () => import('../pages/register/index.vue')},
    {path: '/document', name: 'document', component: () => import('../pages/document/index.vue')},
    {path: '/myapp', name: 'myapp', component: () => import('../pages/myapp/index.vue')},
    {path: '/myapp/createapp', name: 'createapp', component: () => import('../pages/myapp/createapp.vue')},
    {path: '/modifyauthinfo', name: 'modifyauthinfo', component: () => import('../pages/modifyauthinfo/index.vue')},
    {path: '/changepassword', name: 'changepassword', component: () => import('../pages/changepassword/index.vue')}
  ]
})
