import axios from 'axios'
import qs from 'qs'
let config={
  baseurl: "",
  timeout: 180000,
  withCredentials: true,//跨域携带cookies
  //responsetype: 'json',
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'
  },
  validateStatus: function() {
    return true; //错误码交给ajaxCheck去验证
  },
  paramsSerializer: function(params) {
    return qs.stringify(params, {
        arrayFormat: 'brackets'
    })
  },
  transformResponse: [function(data) {
      return data;
  }]
}
let requestPlugin=(config) => {
  config.source = axios.CancelToken.source(); //设置取消函数
  config.cancelToken = config.source.token;
  if (!config.params) config.params = {};
  if (config.method === 'post') {
    if (!config.data) config.data = {};
    if((typeof(FormData)!=="undefined" && config.data instanceof FormData) || contentTypeSetGet(config.headers).indexOf("application/json")>=0){
        //不执行操作
    }else if (config.qs === undefined || config.qs){
      config.data = qs.stringify(config.data);
    }
  } else if (config.method === 'get') {
      // get请求传递数组时，repeat参数格式化为arr=1&arr=2&arr=3，brackets参数格式化为arr[]=1&arr[]=2&arr[]=3
      config.paramsSerializer = function(params) {
          return qs.stringify(params, {
              arrayFormat: 'repeat'
          });
      }
  }
  return config;
}
//获取或设置 content-type 属性
const contentTypeSetGet=(headers,type,value)=>{
  let name;
  if(!(headers instanceof Object))return "";
  var keys=Object.keys(headers?headers:{});
  for(var index=0;index<keys.length;index++){
    let item=keys[index];
    if(String(item).toLocaleLowerCase()==="content-type"){
        name=item;
        break;
    }
  }
  if(!name)return "";
  if(type=="set"){
    headers[name]=value
  }else{
    return headers[name];
  }
}
let responsePlugin=(response) => {
  response.config.source=null;
  let contentType= contentTypeSetGet(response.headers,"get");
  if(contentType.indexOf("application/json")>=0){
      try {
          response.data=JSON.parse(response.data);
      } catch (e) {
          response.data={
              code: 3,
              msg: "服务端返回数据格式错误！"
          }
      }
  }
  //let errorTip="网络拥堵，您的请求需排队处理，请稍后再试";
  let errStatus = {
      300: "资源已被转移至其他位置",
      301: "请求的资源已被永久移动到新URI",
      302: "请求的资源已被临时移动到新URI",
      305: "请求的资源必须通过代理访问",
      400: "错误资源访问请求",
      401: "资源访问未授权",
      403: "资源禁止访问",
      //404: errorTip,
      404: "未找到要访问的资源",
      405: "请更换请求方法",
      406: "无法访问",
      408: "请求超时",
      413: "请求实体过大",
      414: "请求URI过长",
      500: "内部服务器错误",
      501: "未实现",
      503: "服务无法获得",
      504: "接口访问超时"
      // 500: errorTip,
      // 501: errorTip,
      // 503: errorTip,
      // 504: errorTip
  }
  let errorCode=isNaN(response.status)?1:(response.status);
  let errorMessage=`请求服务过程中发生错误【${errorCode}】`;
  //let errorMessage=`${errorTip}【${errorCode}】`;
  if(response.status >=300 && errStatus[String(response.status)]){
      // errorMessage=errStatus[String(response.status)]+`【${errorCode}】`;
      errorMessage=errStatus[String(response.status)]+`【${errorCode}】`;
  }else if (response.status === 200) {
      return response;
  }
  response.data={
      code: 3,
      // message:errorMessage?errorMessage:`${errorTip}【${errorCode}】`
      msg:errorMessage?errorMessage:`请求服务过程中发生错误【${errorCode}】`
  }
  contentTypeSetGet(response.headers,"set","application/json");
  return response;
}

let ajax = (function() {
  let content=this;
  contentTypeSetGet(config.headers,"set",'application/x-www-form-urlencoded; charset=UTF-8');
  let token = localStorage.getItem('token') || ''
  config.headers.token = token
  let ajaxAxios = axios.create(config);
  ajaxAxios.all=axios.all;//并发请求
  ajaxAxios.spread=axios.spread;//分隔请求
  ajaxAxios.interceptors.request.use(requestPlugin.bind(content), (error) => {
      return Promise.reject(error);
  })
  ajaxAxios.interceptors.response.use(responsePlugin.bind(content), function(error) {
      return Promise.reject(error);
  });
  return ajaxAxios;
});
let ajaxJson = (function() {
  let content=this;
  contentTypeSetGet(config.headers,"set",'application/json; charset=UTF-8');
  let token = localStorage.getItem('token') || ''
  config.headers.token = token
  let ajaxAxios = axios.create(config);
  ajaxAxios.all=axios.all;//并发请求
  ajaxAxios.spread=axios.spread;//分隔请求
  ajaxAxios.interceptors.request.use(requestPlugin.bind(content), (error) => {
      return Promise.reject(error);
  })
  ajaxAxios.interceptors.response.use(responsePlugin.bind(content), function(error) {
      return Promise.reject(error);
  });
  ajaxAxios.get=ajax().get;
  ajaxAxios.delete=ajax().delete;
  return ajaxAxios;
});

const ajaxCheck = function(response) {
  let contentType= contentTypeSetGet(response.headers,"get");
  if(contentType.indexOf("application/json")>=0){
      switch (response.data.code) {
          // case "login":
          //     if(callback instanceof Function && callback("login")===false)return;
          //     this.$router.replace({name: 'login'});
          //     break;
          // case "location":
          //     if(callback instanceof Function && callback("location",response.data.message)===false)return;
          //     document.location=response.data.message;
          //     document.close();
          //     break;
          // case "error":
          //     throw(new Error(response.data.message));
          //     break;
          case 0:
              return response.data.data;
          default:
              throw(new Error(response.data.msg));
      }
  }
  return response.data;
}
const ajaxError = function(error, errorMessage) {
  let message= "";
  if(errorMessage){
      message=errorMessage;
  }else if(typeof(error)==="string"){
      message=error;
  }else if(typeof(Error)!=="undefined" && error instanceof Error){
      message=error.message;
  }else{
      message="请求过程中出现问题，请稍后再试【000】";
  }
  message=(navigator.onLine===false || message==="Network Error")?"网络已断开连接，请连接网络后重试！":message;
  if (this.$message) {
      this.$message.error(message);
  } else {
    // alert(message);
  }
  //默认错误处理函数
  this.$log && this.$log(error);
}

let install = {
  install(Vue) {
    Vue.mixin({
      beforeCreate() {
          this.$ajax = ajax.bind(this)();
          this.$ajaxJson = ajaxJson.bind(this)();
          this.$ajaxCheck = ajaxCheck.bind(this);
          this.$ajaxError = ajaxError.bind(this);
      }
    })
  }
}
export {
  ajax,
  ajaxJson,
  ajaxCheck,
  ajaxError
};
export default install;
